<template>
  <div>
    <div
      class="position-absolute d-flex rounded align-items-center"
      style="
        top: 10px;
        right: 10px;
        z-index: 10000;
        background: #f6f6f6b2;
        padding: 1.5rem 2.5rem;
      "
    >
      <span class="me-5">carregando dados de concessionarias...</span>
      <div class="spinner-border text-primary fixed-end" role="status">
        <span class="sr-only">Carregando...</span>
      </div>
    </div>
  </div>
</template>
